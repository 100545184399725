import React from "react";
import styled from "styled-components";

const HeyListen = ({ width, imageSrc, summary, children }) => (
  <Center>
    <Column>
      <Row>
        <TinyColumn width={width}>
          <Image src={imageSrc} />
          <Hey>{summary}</Hey>
          {children}
        </TinyColumn>
      </Row>
    </Column>
  </Center>
);

HeyListen.defaultProps = {
  width: "600px",
};

const Column = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;
  min-width: 500px;
`;

const TinyColumn = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.05);
  border: 5px solid white;
  border-radius: ${(props) => props.theme.border_radius};
  padding: 20px;
  max-width: ${(props) => props.width};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: start;
`;

const Hey = styled.h1`
  font-size: 26px;
  text-align: center;
  margin: 0;
`;

const Image = styled.img`
  margin-top: 40px;
  margin: auto;
  max-width: 200px;
  margin-bottom: 30px;
`;

const Center = styled.div`
  margin: auto;
  width: 70vw;
`;

export default HeyListen;
