import Link from "next/link";
import HeyListen from "../components/HeyListen";
import Button from "../components/Button";
import styled from "styled-components";

function Error({ statusCode }) {
  return (
    <p>
      <HeyListen
        summary={"There was an error"}
        imageSrc={`${process.env.CDN_URL}illustrations/svg/MessyDoodle.svg`}
      >
        <p style={{ textAlign: "center" }}>
          {statusCode
            ? `An error ${statusCode} occurred on server`
            : "An error occurred on client"}
        </p>

        <p>{{ 404: "That page could not be found" }[statusCode]}</p>

        <Row>
          <Link href="/">
            <a className={"hideLink"}>
              <Button>Head home</Button>
            </a>
          </Link>
          <a href="mailto:josh@mathsburst.com" className={"hideLink"}>
            <Button>Contact support</Button>
          </a>
        </Row>
      </HeyListen>
    </p>
  );
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
