import React from "react";
import posed from "react-pose";
import styled from "styled-components";

const Controls = (props) => (
  <Holder
    type={props.type}
    ready={props.ready}
    disabled={props.disabled}
    onClick={() => props.ready && props.onClick()}
    marginBottom={props.marginBottom}
    marginTop={props.marginTop}
    margin={props.margin}
    pose={props.ready && "ready"}
  >
    <Label>{props.children}</Label>
    {props.type && (
      <svg
        style={{ marginLeft: "10px" }}
        width={props.width + "px"}
        height={props.height + "px"}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 46 46`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="controls" fill={props.fill} fill-rule="nonzero">
          {
            {
              okay: (
                <>
                  <Okay
                    fillColour={props.fillColour}
                    pose={props.ready ? "ready" : "not"}
                    d="M22.54,0 C10.0918418,0 0,10.0918418 0,22.54 C0,34.9886647 10.0918418,45.08 22.54,45.08 C34.9886647,45.08 45.08,34.9886647 45.08,22.54 C45.08,10.0918418 34.9886647,0 22.54,0 Z"
                    id="Shape"
                    fill="#9B9B9B"
                  />
                  <path
                    d="M36.9196235,18.3412219 L24.057536,30.8555122 L19.8251265,34.9739798 C19.2139051,35.5686734 18.6021654,35.5686734 17.9909441,34.9739798 L17.91007,34.8952926 L13.6750683,30.7748074 C13.6740314,30.774303 13.673513,30.7727898 13.6724762,30.7722854 L8.22851084,25.4750231 C7.97863071,25.2318999 7.84332217,24.9438846 7.8220668,24.6124904 C7.80132986,24.2821051 7.9366384,23.9734092 8.22799242,23.6899335 L12.4609204,19.571466 C12.6693267,19.3686951 12.972086,19.2370454 13.3676431,19.1760124 C13.7642371,19.1154838 14.1012124,19.2204 14.378569,19.4907611 L18.9085537,23.8982528 L30.7695653,12.3574644 C31.018927,12.1143411 31.3149468,11.9826914 31.6555511,11.9620108 C31.9956369,11.9418346 32.3123937,12.0734843 32.6042661,12.3574644 L36.8366757,16.4754275 C37.0450819,16.6781984 37.1803905,16.9727709 37.2431197,17.3581364 C37.3048121,17.7435019 37.1974985,18.0713652 36.9196235,18.3412219 Z"
                    id="tick"
                    fill="#FFFFFF"
                  />
                </>
              ),
              replay: (
                <path
                  d="M23,0 C35.7027191,0 46,10.2977978 46,23 C46,35.7027191 35.7027191,46 23,46 C10.2977978,46 0,35.7027191 0,23 C0,10.2977978 10.2977978,0 23,0 Z M13.2484947,13.0534778 L13.2484947,10.3278186 C13.2484947,9.59268749 12.6524954,9 11.917293,9 L9.33120174,9 C8.59695917,9 8,9.59448462 8,10.3278186 L8,21.2622646 C8,21.6301767 8.1492809,21.9624107 8.38982758,22.2007439 C8.63115954,22.4411747 8.96476428,22.5900832 9.33329034,22.5900832 L9.83697315,22.5900832 C9.83699938,22.5900839 13.302766,22.6730032 14.5144288,22.5900832 L20.312796,22.5900832 C21.0509564,22.5900832 21.6460863,21.9965298 21.6460863,21.2643447 L21.6460863,18.6888666 C21.6460863,17.9576374 21.0491519,17.3631281 20.312796,17.3631281 L17.0259993,17.3631281 C18.6626234,15.4444076 21.1030823,14.2269551 23.8290642,14.2269551 C28.7567923,14.2269551 32.7515053,18.2052738 32.7515053,23.1127787 C32.7515053,28.0202836 28.7567923,31.9986024 23.8290642,31.9986024 C21.7736122,31.9986024 19.832532,31.3013765 18.3079878,30.1366209 L17.2792233,29.1812197 C16.7406445,28.681048 15.8966395,28.7088123 15.3952327,29.2442987 L13.6315227,31.1278841 C13.1307704,31.6626713 13.1606661,32.5033239 13.6997585,33.0039726 L15.2021874,34.3992604 C15.3619453,34.5476256 15.5485771,34.6495385 15.7451696,34.7055267 C18.037912,36.2941932 20.824274,37.2255575 23.8290642,37.2255575 C31.6554559,37.2255575 38,30.9070512 38,23.1127787 C38,15.3185063 31.6554559,9 23.8290642,9 C19.5216333,9 15.7771115,10.3788061 13.2484947,13.0534778 Z"
                  id="Replay"
                />
              ),

              quit: (
                <path
                  d="M23,0 C35.7027191,0 46,10.2977978 46,23 C46,35.7027191 35.7027191,46 23,46 C10.2977978,46 0,35.7027191 0,23 C0,10.2977978 10.2977978,0 23,0 Z M23,18.0502525 L16.636039,11.6862915 C16.2455147,11.2957672 15.6123497,11.2957672 15.2218254,11.6862915 L11.6862915,15.2218254 C11.2957672,15.6123497 11.2957672,16.2455147 11.6862915,16.636039 L18.0502525,23 L11.6862915,29.363961 C11.2957672,29.7544853 11.2957672,30.3876503 11.6862915,30.7781746 L15.2218254,34.3137085 C15.6123497,34.7042328 16.2455147,34.7042328 16.636039,34.3137085 L23,27.9497475 L29.363961,34.3137085 C29.7544853,34.7042328 30.3876503,34.7042328 30.7781746,34.3137085 L34.3137085,30.7781746 C34.7042328,30.3876503 34.7042328,29.7544853 34.3137085,29.363961 L27.9497475,23 L34.3137085,16.636039 C34.7042328,16.2455147 34.7042328,15.6123497 34.3137085,15.2218254 L30.7781746,11.6862915 C30.3876503,11.2957672 29.7544853,11.2957672 29.363961,11.6862915 L23,18.0502525 Z"
                  id="Quit"
                />
              ),

              download: (
                <g
                  id="Symbols"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="tick-selected-copy-3"
                    fill="#4A90E2"
                    fill-rule="nonzero"
                  >
                    <path
                      d="M23,0 C10.2977978,0 0,10.2977978 0,23 C0,35.7027191 10.2977978,46 23,46 C35.7027191,46 46,35.7027191 46,23 C46,10.2977978 35.7027191,0 23,0 Z M23,35 L11,17 L35,17 L23,35 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              ),

              play: (
                <>
                  <path
                    d="M23,0 C10.2977978,0 0,10.2977978 0,23 C0,35.7027191 10.2977978,46 23,46 C35.7027191,46 46,35.7027191 46,23 C46,10.2977978 35.7027191,0 23,0 Z"
                    id="Shape"
                    fill="#4A90E2"
                  />
                  <polygon
                    id="Triangle"
                    fill="#FFFFFF"
                    transform="translate(26.000000, 23.000000) rotate(90.000000) translate(-26.000000, -23.000000) "
                    points="26 12 37 34 15 34"
                  />
                </>
              ),
            }[props.type]
          }
        </g>
      </svg>
    )}
  </Holder>
);

export default Controls;

Controls.defaultProps = {
  type: null,
  disabled: false,
  width: "25",
  height: "25",
  fill: "#00CDFF",
  ready: true,
  fillColour: "#000",
  marginBottom: 0,
  marginTop: 0,
  onClick: () => {},
};

let Holder = posed.button({
  hoverable: ({ ready }) => ready,
  init: {
    scale: 1,
    color: "#000",
    background: "#fff",

    borderColor: "rgb(185, 185, 185)",
  },
  hover: {
    scale: 0.95,
    borderColor: "rgb(0, 0, 0)",
  },
  ready: {
    scale: 1,
    color: "#000",
    background: "#fff",
    borderColor: "rgb(185, 185, 185)",
  },
});

let Okay = posed.path({
  ready: { fill: ({ fillColour }) => fillColour },
  not: { fill: ({ fillColour }) => fillColour },
});

Holder = styled(Holder)`
  padding: 10px;
  min-width: 80px;
  background-color: white;
  border: 1px solid rgb(185, 185, 185);
  border-radius: 12px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop || "0px"};
  margin: ${(props) => props.margin || "auto"};
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;

  :focus {
    box-shadow: 0 0 3pt 2pt #b79af9;
  }

  &[disabled] {
    text-decoration: line-through;
    opacity: 0.75;
  }
`;

const Label = styled.span`
  font-size: ${(props) => props.theme.FONT_SIZE_BODY};

  > svg {
    vertical-align: text-bottom;
    margin-right: 5px;
  }

  text-decoration: none;
`;
